import React, {useEffect} from 'react';
import CardHeader from "../../components/CardHeader/index.js";
import './index.css';
import {useNavigate} from "react-router-dom";

const TeamPage = () => {
    const navigator = useNavigate();
    return (
        <>
            <div className="TeamPage mobile">
                <CardHeader title="专家团队"/>
                <img src="/assets/service/team/c1_mobile.png" alt="" />


                <div className="card-container">
                    <img src="/assets/service/facility/c6.png" alt="" onClick={() => {
                        navigator('/store-query')
                    }}/>
                </div>

            </div>
            <div className="TeamPage desktop">
                <CardHeader title="专家团队"/>
                <img src="/assets/service/team/c1.png" alt="" />
                <div className="description">
                    依托索诺瓦强大的技术力量和纵贯全球的专家团队，每天有<span>超过1000名</span>听力专家为听损人群带来更加专业和贴心的服务。
                </div>
                <img src="/assets/service/team/c2.png" alt=""/>

                <div className="card-container">
                    <img src="/assets/service/facility/c6.png" alt="" onClick={()=>{
                        navigator('/store-query')
                    }}/>
                </div>

            </div>
        </>

    )
}
export default TeamPage;