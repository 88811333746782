import React, {useEffect, useState} from 'react';
import CardHeader from "../../components/CardHeader";

import HearingCard from "./HearingCard";
import HearingCardLarge from "./HearingCardLarge";
import './index.css';
import {getArticleList} from "../../config/util.js";

const HearingPage = () => {
    const [articleList, setArticleList] = useState([]);
    useEffect(() => {
        //获取文章列表
        getArticleList().then(res => {
            console.log(res);
            if (res.status === 200) {
                const article = res.data.data.list;
                //筛选出article的type2等于'听力科普'的内容
                setArticleList(article.filter(item => item.type2 === '听力科普'));
            }
        });
    }, [])
    return (
        <div className="HearingPage">
            <CardHeader title="听力科普"/>
            <div className='card-container'>
                {articleList[0] &&
                    <HearingCardLarge id={articleList[0].id} title={articleList[0].title} tags={articleList[0].tags}
                                      articleImg={articleList[0].coverUrl}/>
                }
                {/*从第2个开始循环*/}
                {articleList.slice(1).map(item => {
                    return <HearingCard id={item.id} title={item.title} tags={item.tags} articleImg={item.coverUrl}/>
                })}
            </div>
        </div>
    )
}
export default HearingPage;