import React from "react";
import './index.css';
import Button from "../../../components/Button/index.js";

import {useNavigate} from "react-router-dom";
function HearingCard(props) {
    let {
        title,
        tags,
        id,
        articleImg
    } = props;
    // 如果tags是字符串
    if (typeof tags === 'string') {
        //按逗号分隔
        tags = tags.split(',');
    }
    const navigate = useNavigate()

    return (
        <div className="hearingCard" onClick={()=>{
            navigate('/article?id='+id)
        }}>
            <div className="left">
                <div className='card-header'>
                    <div className='card-title'>{title}</div>
                </div>
                <div className='card-other' onClick={() => {
                }}>
                    {tags && tags.map(tag => {
                        return <Button label={tag} type="primary-ghost"/>
                    })}
                </div>
            </div>
            <div className="right">
                <img src={articleImg} alt=""/>
            </div>

        </div>
    )

}

export default HearingCard;